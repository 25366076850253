.navlink {
  --edge-margin: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--color-nav-back);
  color: var(--color-nav-fore);

  .logo {
    max-width: 98%;
    height: auto;
    margin: 0 auto;
  }
  .active svg.logo g g path {
    fill: green;
  }

  a {
    color: white;
    display: block;
    text-decoration: none;
    padding: var(--edge-margin);

    &.active {
      background: var(--color-nav-back-alt);
      color: var(--color-nav-fore-alt);
      font-weight: bold;
    }
  }
}