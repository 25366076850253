button, a.button {
    background-color: var(--color-accent);
    color: var(--text-on-light);
    font-size: calc(var(--text-size-base) * .8 );
    padding: .2em 1rem .2em 1rem;
    text-transform: uppercase;
    border-radius: .25em;
    text-decoration: none;
}

button.button-danger, a.button.button-danger {
    background-color: var(--color-alert);
    color: white;
}