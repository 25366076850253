@use "../theme/vars" as *; // import without namespace

form {
    --form-gap: 1rem;

    .form__error-banner {
        background-color: var(--color-alert, red);
        color: white;
        padding: var(--form-gap);
        margin-bottom: var(--form-gap);
    }

    .form-input-group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: var(--form-gap);
        margin-bottom: var(--form-gap);
    }

    .button-group {
        display: flex;
        justify-content: center;
    }


    & label {
        display: block;
        font-size: smaller;
        text-transform: uppercase;
        color: var(--color-nav-back);
    }

}

@media (min-width: $breakpoint-desktop) {
    form {
        .form-input-group {
            flex-direction: row;

            .form-input {
                flex: 1;

                &.field-wide {
                    flex: 3;
                }
            }
        }

        .button-group {
            justify-content: end;
        }
    }
}