@use "../theme/vars" as *; // import without namespace

.expense-list {
    --pad: 1rem;
    display: flex;
    flex-direction: column;
    --grid-cols: ;



    &__column-header {
        display: grid;
        grid-template-columns: 5fr 1fr;

        background-color: var(--color-content-back-alt);
        padding: var(--pad);


        &--name {
        }

        &--amount {
            text-align: right;
        }
    }

    &__list {
        
        .expense-single{
            --border: 1px solid var(--color-content-bg-light);
            padding: .5rem var(--pad) .5rem var(--pad);
            text-decoration: none;
            color:var(--text-on-light);
            display: flex;
            flex-direction: column;
            gap: .6rem;
            border-top:none;
            border-left: var(--border);
            border-bottom: var(--border);
            border-right: var(--border);

            outline: 2px solid transparent; // focus selector

            &:nth-child(even){
                background-color: var(--color-content-back-alt);
            }

            &__description {
                word-break: break-all;
            }
            &__amount {
                text-align: left;
                font-weight: 700;
            }

            &:hover, &:focus-visible {
                outline: 2px solid var(--color-nav-back);
                background-color: var(--color-nav-back-alt);
                transition: 
                background-color .3s ease-in,
                outline-color .3s ease-in; 

            }
        }
    }
}

@media (min-width: $breakpoint-desktop) {
    .expense-list {
        .expense-single{
            display: grid;
            grid-template-columns: 4fr 1fr 1fr;
            align-items: center;

             &__amount {
                text-align: right;
            }

        }

    }


}