@use "../theme/vars"  as *;

form.filters-form {
    display: flex;
    flex-direction: column;
    gap:1rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;



    & input, & select {
    font-size: 1.1rem;
    line-height: 1.0;
    padding: .25em;
    }
   

    &>div {
        flex: 1;
        display: flex;
        flex-direction: column;

    }

}


@media (min-width:$breakpoint-desktop){
    form.filters-form {
        flex-direction: row;
        margin-bottom: unset;
    }
}



// override the 100% width of default stylesheet
.react-datepicker-wrapper .react-datepicker__input-container {
    width: fit-content;
    padding-right: 2rem;
    & input {
        letter-spacing: -0.05rem;
    }
}