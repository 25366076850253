@use 'vars';
@use 'utility';
@use 'typography';
@use 'layouts';
@use 'buttons';
@use 'forms';

// super-basic reset
html {
  background-image: unset;
}


:root {
  --text-on-dark: white;
  --text-on-light: rgb(2, 39, 63);
  --color-content-back-alt: #f4f4f4;

  --color-accent: #c2e4bd;
  --color-alert: rgb(255, 111, 0);

  --color-nav-back: teal;
  --color-nav-fore: var(--text-on-dark);
  --color-nav-back-alt: peachpuff;
  --color-nav-fore-alt: var(--text-on-light);
  --color-content-bg-light: rgb(231, 231, 231);

  --card-radius: .5rem;


}



body {
  margin: 0;
  color: var(--text-on-light);
  font-size: var(--text-size-base);
  background-color: var(--color-content-bg-light);
}




.logged-in-status {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    padding: .5rem;
    max-width: fit-content;
    background: var(--color-nav-back);
    color: var(--color-nav-fore);
    border-radius: .4em;
    text-transform: uppercase;
    opacity: .5;
}

.on-dark {
  color: var(--text-on-dark);
}

@media (prefers-reduced-motion: no-preference) {

}