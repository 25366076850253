:root {
    --grid-gap: 1rem;
    --header-padding: 1.5rem var(--grid-gap) ;
}

html {
    $breakpoint-desktop: 45rem;
}



.App {
  display: grid;
  grid-template-columns: 10ch 1fr;
  grid-template-rows: 1fr fit-content(4rem);
  column-gap: 0;
  min-height: 100svh;
  grid-template-areas:
    "nav pagehead"
    "nav  main"
    "foot  foot";
}

.App>.sidebar {
  grid-area: nav;
}

.App>footer {
    grid-area: foot;
    padding: var(--grid-gap);
}

#page-main {
    position: relative;
    overflow-x: hidden;
    &>header {
        grid-area: pagehead;
        display: flex;
        justify-content: space-between;
        background-color: var(--color-content-back-alt);
        padding: var(--header-padding);
        margin-bottom: calc(var(--grid-gap) * 2);
        box-shadow: var(--color-nav-back) 0px 3px 6px;
    }
    & > section {
        grid-area: main;
       // margin-top: var(--grid-gap); /* since we don't use gap on grid */
        padding-right: var(--grid-gap);
        padding-left: var(--grid-gap);

        &>* {
            margin-bottom: var(--grid-gap);
        }
    }

}




.focus-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-height: 100svh;
    &.with-background {
        background-image: url('../../public/images/background-splash.jpg');
        background-size: cover;
        background-repeat: no-repeat;

    }
}

.App-footer {
    background-color: red;
}





